import {
  QRS_REQUEST, QRS_SUCCESS, QRS_FAILURE,
  QR_REQUEST, QR_SUCCESS, QR_FAILURE,
  QR_ADD_REQUEST, QR_ADD_SUCCESS, QR_ADD_FAILURE,
  QR_UPDATE_REQUEST, QR_UPDATE_SUCCESS, QR_UPDATE_FAILURE,
  QR_RESET,
  PREBOOKING_REQUEST, PREBOOKING_SUCCESS, PREBOOKING_FAILURE,
  BOOKING_REQUEST, BOOKING_SUCCESS, BOOKING_FAILURE,
  CANCEL_PREBOOKING_REQUEST, CANCEL_PREBOOKING_SUCCESS, CANCEL_PREBOOKING_FAILURE,
  CUSTOMER_REQUEST, CUSTOMER_SUCCESS, CUSTOMER_FAILURE,
  PDASALE_REQUEST, PDASALE_SUCCESS, PDASALE_FAILURE,
  REDSYS_REQUEST, REDSYS_SUCCESS, REDSYS_FAILURE
} from '../actions/qrs'

const INITIAL_STATE = {
  qrs: null, qrsNeedRefresh: false, qr: null, error: null, loading: false,
  customer: null, prebooking: null, cancelprebooking: null, booking: null,
  pdasale: null, redsys: null
}

const qrsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QRS_REQUEST:
      return {
        ...state,
        qrs: null, error: null, loading: true
      }
    case QRS_SUCCESS:
      return {
        ...state,
        qrs: action.response, error: null, loading: false
      }
    case QRS_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case QR_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case QR_SUCCESS:
      return {
        ...state,
        qr: action.response, error: null, loading: false
      }
    case QR_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case QR_ADD_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case QR_ADD_SUCCESS:
      return {
        ...state,
        qr: action.response, qrsNeedRefresh: true, error: null,
        loading: false
      }
    case QR_ADD_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case QR_UPDATE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case QR_UPDATE_SUCCESS:
      return {
        ...state,
        qr: action.response, qrsNeedRefresh: true, error: null,
        loading: false
      }
    case QR_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case QR_RESET:
      return {
        ...state,
        qr: null, error: null, loading: false
      }
    case PREBOOKING_REQUEST:
      return {
        ...state,
        prebooking: null, error: null, loading: true
      }
    case PREBOOKING_SUCCESS:
      return {
        ...state,
        prebooking: action.response, error: null, loading: false
      }
    case PREBOOKING_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case BOOKING_REQUEST:
      return {
        ...state,
        booking: null, error: null, loading: true
      }
    case BOOKING_SUCCESS:
      return {
        ...state,
        booking: action.response, error: null, loading: false
      }
    case BOOKING_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CANCEL_PREBOOKING_REQUEST:
      return {
        ...state,
        cancelprebooking: null, error: null, loading: true
      }
    case CANCEL_PREBOOKING_SUCCESS:
      return {
        ...state,
        cancelprebooking: action.response, error: null, loading: false
      }
    case CANCEL_PREBOOKING_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CUSTOMER_REQUEST:
      return {
        ...state,
        customer: null, error: null, loading: true
      }
    case CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.response, error: null, loading: false
      }
    case CUSTOMER_FAILURE:
      return {
        ...state,
        customer: action.error, loading: false
      }
    case PDASALE_REQUEST:
      return {
        ...state,
        pdasale: null, error: null, loading: true
      }
    case PDASALE_SUCCESS:
      return {
        ...state,
        pdasale: action.response, error: null, loading: false
      }
    case PDASALE_FAILURE:
      return {
        ...state,
        pdasale: action.error, loading: false
      }
    case REDSYS_REQUEST:
      return {
        ...state,
        redsys: null, error: null, loading: true
      }
    case REDSYS_SUCCESS:
      return {
        ...state,
        redsys: action.response, error: null, loading: false
      }
    case REDSYS_FAILURE:
      return {
        ...state,
        redsys: action.error, loading: false
      }
    default:
      return state
  }
}

export default qrsReducer

