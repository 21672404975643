import React from 'react'
import { history } from '../store'
import { Button } from 'reactstrap';

class QRList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filters: {
        code: '',
        customer: '',
      },
      showButton: false,
      buttonPosition: { x: 0, y: 0 },
      selectedURL: null
    };

    this.handleRightClick = this.handleRightClick.bind(this);
  }

  goTo(id, e) {
    history.push(`/qrs/${id}`)
  }

  handleFilterChange(filterKey, value) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [filterKey]: value,
      },
    }));
  }

  returnQrType(type) {

    switch (type) {
      case 'pay': return 'Pago';
      case 'free': return 'Gratis';
      case 'test': return 'Test';
    }

  }

  returnQrMode(mode) {

    switch (mode) {
      case 0: return 'MTO DIARIO';
      case 1: return 'OTO DIARIO SIN PIN';
      case 2: return 'BAQUEIRA';
      case 3: return 'OTO DIARIO CON PIN';
    }

  }

  handleRightClick(e, url) {
    e.preventDefault();
    const elementRect = e.target.getBoundingClientRect();
    this.setState({
      showButton: true,
      buttonPosition: {
        x: elementRect.left + window.scrollX,
        y: elementRect.top + window.scrollY,
      },
      selectedURL: url,
    });
  }

  openURL(url, e) {
    e.stopPropagation();
    if (url) {
      window.open(url, '_blank'); // Open the URL in a new tab
    }
  }

  hideButton = () => {
    this.setState({ showButton: false, selectedURL: null });
  }

  handleButtonClick = () => {
    const { selectedURL } = this.state;
    if (selectedURL) {
      // Copy the URL to the clipboard
      navigator.clipboard.writeText(`${selectedURL}`)
        .then(() => {
          console.log('URL copied to clipboard');
        })
        .catch(err => {
          console.error('Failed to copy URL to clipboard:', err);
        });

      this.hideButton(); // Hide the button after clicking
    }
  }

  render() {
    const { filters } = this.state;
    const { qrs } = this.props;

    // Apply the filters to filter the qrs array
    const filteredqrs = qrs.filter(qr => {
      const { code: codeFilter, customer: customerFilter } = filters;

      if (
        codeFilter &&
        (!qr.Code ||
          !qr.Code.toLowerCase().includes(codeFilter.toLowerCase()))
      ) {
        return false;
      }

      if (
        customerFilter &&
        (!qr.Customer.Fullname ||
          !qr.Customer.Fullname.toLowerCase().includes(customerFilter.toLowerCase()))
      ) {
        return false;
      }

      // If all conditions pass, include the customer in the filtered list
      return true;
    });

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Código</th>
              <th scope="col">Cliente</th>
              <th scope="col">Zona</th>
              <th scope="col">Tipo</th>
              <th scope="col">Modo</th>
              <th scope="col">Ventas</th>
              <th scope="col">Url</th>
            </tr>
            <tr>
              <th scope="col">
                <input
                  type="text"
                  placeholder="Code"
                  value={filters.code}
                  onChange={e => this.handleFilterChange('code', e.target.value)}
                />
              </th>
              <th scope="col">
                <input
                  type="text"
                  placeholder="Cliente"
                  value={filters.customer}
                  onChange={e => this.handleFilterChange('customer', e.target.value)}
                />
              </th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {filteredqrs
              .sort((a, b) => {
                // Ensure Fullname is compared in a case-insensitive manner
                const fullnameA = a.Customer.Fullname.toLowerCase();
                const fullnameB = b.Customer.Fullname.toLowerCase();
                if (fullnameA < fullnameB) return -1;
                if (fullnameA > fullnameB) return 1;
                return 0;
              })
              .map(qr => (
                <tr key={qr.Code}
                  onClick={e => this.goTo(qr._id, e)}
                  onContextMenu={(e) => this.handleRightClick(e, qr.Url)}>
                  <th scope="row">{qr.Code}</th>
                  <td scope="row">{qr.Customer.Fullname}</td>
                  <td scope="row">Zona {qr.Zone}</td>
                  <td scope="row">{this.returnQrType(qr.Type)}</td>
                  <td scope="row">{this.returnQrMode(qr.Mode)}</td>
                  <td scope="row">{qr.LastSale}</td>
                  <td scope="row">
                    <button
                      className="btn btn-info mb-3"
                      onClick={(event) => this.openURL(qr.Url, event)}
                    >
                      <i className="fas fa-link"></i>
                      <span className="d-none d-sm-inline ml-1">Abrir</span>
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {this.state.showButton && (
          <div
            style={{
              position: 'absolute',
              top: `${this.state.buttonPosition.y - 200}px`,
              left: `${this.state.buttonPosition.x - 200}px`,
              zIndex: 1000,
            }}
          >
            <Button
              color="primary"
              className={`btn-baqueira`}
              onClick={() => {
                this.handleButtonClick(); // Handle blocked locker logic
              }}
            >
              <i className="fas fa-door-open d-block ml-auto mr-auto mb-2"></i>
              Copiar URL
            </Button>
            <Button
              color="secondary"
              className={`btn-baqueira`}
              onClick={() => {
                this.hideButton(); // Hide button after action
              }}
            >
              <i className="fas fa-times d-block ml-auto mr-auto mb-2"></i>
              Cancelar
            </Button>
          </div>
        )}
      </div>
    )
  }
}

export default QRList