import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Field, FieldArray, reduxForm, SubmissionError } from 'redux-form'

import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import { fetchModule, updateModule } from '../actions/modules'
import { renderInputField, renderTextAreaField, renderSelectField } from '../utils/forms'

class ModuleEditForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { loaded: false }
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const { modules, fetchModule, match } = this.props

    if (!modules.module) {
      fetchModule(match.params.Code)
    } else {
      this.setState({ loaded: true })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.loaded && nextProps.modules.module) {
      this.setState({ loaded: true })
    }
  }

  submit(props) {
    const { updateModule, modules } = this.props

    return updateModule(modules.module.Code, props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  renderPairing(props) {
    const { fields } = props

    return (
      <div>
        <div className="table-responsive">
          <table className="table table-sm table-stripped">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Index</th>
                <th scope="col">Número de serie</th>
                <th scope="col">PIN</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {fields.map((registerlocker, index) => (
                <tr key={index}>
                  <td>
                    {index + 1}
                  </td>
                  <td>
                    <Field
                      name={`${registerlocker}.SN`}
                      type="text"
                      component={renderInputField}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${registerlocker}.Pin`}
                      type="text"
                      component={renderInputField}
                    />
                  </td>
                  <td>
                    <button className="btn btn-danger mr-1" type="button"
                      onClick={() => fields.remove(index)}>
                      <i className="fas fa-trash mr-1"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="text-right">
          <button className="btn bg-vw-light text-white" type="button"
            onClick={() => fields.push({})}>
            <i className="fas fa-plus mr-1"></i>
            Añadir
          </button>
        </div>
      </div>
    )
  }

  render() {
    const { modules, error, pristine, submitting, handleSubmit } = this.props

    if (!this.state.loaded) {
      if (modules.error) {
        return (
          <ErrorMessage message={modules.error.message} />
        )
      }

      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <h1>
          <span className="text-vw-dark">EDITAR </span>
          <span className="text-vw-light">MÓDULO</span>
        </h1>
        <hr className="bg-vw-light" />
        <div className="row">
          <div className="col">
            <Box title="Configuración" icon="cubes">
              <div className="row">
                <div className="col">
                  <Field name="Code"
                    type="text"
                    component={renderInputField}
                    label="Número de Serie" />
                </div>
                <div className="col">
                  <Field name="Mode"
                    component={renderSelectField}
                    label="Modo">
                    <option value="">Selecciona un modo...</option>
                    <option value="B">Módulo básico</option>
                    <option value="C">Módulo de carga</option>
                  </Field>
                </div>
                <div className="col">
                  <Field name="Wide"
                    component={renderSelectField}
                    label="Ancho">
                    <option value="">Selecciona un ancho...</option>
                    <option value="N">Normal</option>
                    <option value="W">Ampliado (Wide)</option>
                  </Field>
                </div>
                <div className="col">
                  <Field name="Espressif"
                    component={renderSelectField}
                    label="Espressif">
                    <option value="">Selecciona un espressif...</option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                  </Field>
                </div>
                <div className="col">
                  <Field name="Gender"
                    component={renderSelectField}
                    label="Género">
                    <option value="">Selecciona un género...</option>
                    <option value="">Sin género</option>
                    <option value="M">Masculino</option>
                    <option value="F">Femenino</option>
                  </Field>
                </div>
                <div className="col">
                  <Field name="HardType"
                    component={renderSelectField}
                    label="Cierre">
                    <option value="">Selecciona un modelo...</option>
                    <option value="2">Pincho</option>
                    <option value="3">Slide</option>
                  </Field>
                </div>
              </div>
              <FieldArray name="RegisterLockers" component={this.renderPairing} />
            </Box>
          </div>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <button className="btn bg-vw-light text-white mr-1 save" type="submit"
          disabled={pristine || submitting}>
          Guardar
        </button>
      </form>
    )
  }
}

ModuleEditForm = reduxForm({
  form: 'ModuleEditForm',
  enableReinitialize: true,
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/modules/${result.response.Code}`)
  }
})(ModuleEditForm)

const mapStateToProps = state => ({
  modules: state.modules,
  initialValues: state.modules.module
})

const mapDispatchToProps = dispatch => ({
  fetchModule: bindActionCreators(fetchModule, dispatch),
  updateModule: bindActionCreators(updateModule, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ModuleEditForm)


