import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import AssignedItemList from '../components/AssignedItemList'
import ErrorMessage from '../components/ErrorMessage'

import { fetchContract, updateContract } from '../actions/contracts'

class ContractDetailsView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      closing: false,
      terminateConfirmShown: false
    }

    this.terminateContract = this.terminateContract.bind(this)
    this.terminateConfirmShow = this.terminateConfirmShow.bind(this)
    this.terminateConfirmToggle = this.terminateConfirmToggle.bind(this)
  }

  componentDidMount() {
    this.props.fetchContract(this.props.match.params.id)
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.deleting && !nextProps.loading) {
      this.props.push('/contracts')
    }
  }

  terminateContract() {
    const { updateContract, contract } = this.props

    this.setState({ deleting: true })
    updateContract(contract.number, { ...contract, Status: 'TERMINATED' })
  }

  terminateConfirmShow() {
    this.setState({ terminateConfirmShown: true })
  }

  terminateConfirmToggle() {
    this.setState({ terminateConfirmShown: !this.state.terminateConfirmShown })
  }

  workingMode(type) {
    switch (type) {
      case "0":
        return "MTO DIARIO"
      case "1":
        return "MTO DIARIO + SERVICIOS"
      case "2":
        return "MTO DIARIO TIEMPO SIN PIN"
      case "3":
        return "MTO DIARIO TIEMPO CON PIN"
      case "4":
        return "OTO DIARIO SIN PIN"
      case "5":
        return "OTO DIARIO CON PIN"
      case "6":
        return "TAQUILLAS BAQUEIRA"
      case "7":
        return "TAQUILLAS AQUARIAZ"
    }
  }

  render() {
    const { role, type, contract, error, loading } = this.props

    if (error) {
      return (
        <ErrorMessage message={error.message} />
      )
    }

    if (!contract || loading) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col col-12 col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-light">CONTRATO DE MÁQUINA </span>
              <span className="text-vw-dark">{contract.Machine.Serial}</span>
            </h1>
          </div>
          {(role === 'ADMIN' && type === 'GOD') && contract.Status === 'VALID' &&
            <div className="col col-12 col-xs-12 col-sm-6 col-md-6 text-right">
              <Link to={`/contracts/${contract._id}/edit`}>
                <button className="btn bg-vw-light text-white mr-1">
                  <i className="fas fa-edit mr-1"></i>Editar
                </button>
              </Link>
              <button className="btn btn-warning mr-1" onClick={this.terminateConfirmShow}>
                <i className="fas fa-times-circle mr-1"></i>Terminar
              </button>
            </div>
          }
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Box title="Validez" icon="sliders-h">
              <PropertyLabel name="Fecha inicio"
                value={(new Date(contract.StartDate)).toDateString()} />
              <PropertyLabel name="Fecha fin"
                value={(new Date(contract.EndDate)).toDateString()} />
            </Box>
            <Box title="Contrato" icon="power-off">
              <PropertyLabel name="Estado"
                value={contract.Status} />
              <PropertyLabel name="Última edición"
                value={contract.Executor} />
            </Box>
            <Box title="Resumen" icon="sliders-h">
              <PropertyLabel name="Nombre"
                value={contract.Customer.Fullname} />
              <PropertyLabel name="Zona"
                value={contract.Zone} />
              {contract.Customer.Sector &&
                <PropertyLabel name="Sector"
                  value={contract.Customer.Sector.description} />
              }
              <PropertyLabel name="Localización"
                value={contract.Location} />
              <PropertyLabel name="Moneda"
                value={contract.Currency} />
              <PropertyLabel name="Tasa de conversión"
                value={contract.ConversionRate} />
              <PropertyLabel name="Report AE Italia"
                value={contract.ReportAE} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Funcionamiento" icon="power-off">
              <PropertyLabel name="Working Mode"
                value={this.workingMode(contract.WorkingMode)} />
              <PropertyLabel name="Rango de tiempo"
                value={contract.TimeSlots} />
              <PropertyLabel name="Tiempo de cortesía"
                value={contract.TimeCourtesy} />
            </Box>
            <Box title="Ticket" icon="receipt">
              <PropertyLabel name="Cabecera" ml
                value={contract.TicketHeader} />
              <PropertyLabel name="IVA" ml
                value={contract.TicketIVA} />
              <PropertyLabel name="Footer" ml
                value={contract.TicketFooter} />
            </Box>
          </div>
          <div className="col col-12 col-xs-12 col-md-4">
            <Box title="Artículos" icon="shopping-cart">
              <AssignedItemList items={contract.Items} currency={contract.Currency} conversionRate={contract.ConversionRate} />
            </Box>
          </div>
        </div>
        {/* Confirm terminate */}
        <div>
          <Modal isOpen={this.state.terminateConfirmShown}
            toggle={this.terminateConfirmToggle}>
            <ModalHeader toggle={this.terminateConfirmToggle}>
              Confirmar
            </ModalHeader>
            <ModalBody>
              <p>¿Estás seguro de terminar el contrato?</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary mr-1" onClick={this.terminateContract}>
                Terminar
              </Button>
              <Button color="secondary" onClick={this.terminateConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    )
  }
}



const mapStateToProps = state => ({
  contract: state.contracts.contract,
  loading: state.contracts.loading,
  error: state.contracts.error,
  role: state.auth.role,
  type: state.auth.type
})

const mapDispatchToProps = dispatch => ({
  fetchContract: bindActionCreators(fetchContract, dispatch),
  updateContract: bindActionCreators(updateContract, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ContractDetailsView)


