import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Field, FieldArray, reduxForm, SubmissionError } from 'redux-form'
import * as cc from 'currency-codes'

import Box from '../components/Box'

import { fetchContract, updateContract } from '../actions/contracts'
import { fetchMachines, resetMachines } from '../actions/machines'
import { fetchCustomers } from '../actions/customers'


import { renderInputField, renderSelectField, renderTextAreaField } from '../utils/forms'
import QRZonesList from '../components/QRZonesList'

class ContractEditForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      fCustomers: null,
      fMachines: null,
      customerSelected: "",
      showDynamicField: false
    }

    this.load = this.load.bind(this)
    this.renderItems = this.renderItems.bind(this)
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const { fetchContract, fetchMachines, fetchCustomers,
      match } = this.props

    fetchContract(match.params.id)
    fetchMachines()
    fetchCustomers()

    this.load(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps)
  }

  load(props) {
    const { contract, machines, customers } = props

    if (!this.state.loaded &&
      contract && machines && customers) {

      this.setState({
        loaded: true,
        fMachines: machines.filter(machine =>
          (machine.Customer === undefined) || (machine._id === contract.Machine._id))
      })
    }
  }

  submit(props) {
    const { updateContract, contract } = this.props

    props.Executor = this.props.fullname

    return updateContract(contract._id, props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  renderItems(props) {
    const { fields } = props

    return (
      <div>
        <div className="table-responsive">
          <table className="table table-sm table-stripped">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Artículo</th>
                <th scope="col">Precio (PVP)</th>
                <th scope="col">Comisión Cliente (%)</th>
                <th scope="col">Stock</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {fields.map((member, index) => (
                <tr key={index}>
                  <td>
                    <Field name={`${member}.item`}
                      component={renderSelectField}>
                      <option value="" key="default">Selecciona un artículo...</option>
                      <option value="LOCKERBNS" key="LOCKERBNS">Básica</option>
                      <option value="LOCKERCNS" key="LOCKERCNS">Básica Carga</option>
                      <option value="LOCKERBWS" key="LOCKERBWS">Básica XL</option>
                      <option value="LOCKERCWS" key="LOCKERCWS">Básica Carga XL</option>
                      <option value="LOCKERBNM" key="LOCKERBNM">Grande</option>
                      <option value="LOCKERCNM" key="LOCKERCNM">Grande Carga</option>
                      <option value="LOCKERBWM" key="LOCKERBWM">Grande XL</option>
                      <option value="LOCKERCWM" key="LOCKERCWM">Grande Carga XL</option>
                      <option value="LOCKERBNL" key="LOCKERBNL">Jumbo</option>
                      <option value="LOCKERCNL" key="LOCKERCNL">Jumbo Carga</option>
                      <option value="LOCKERBWL" key="LOCKERBWL">Jumbo XL</option>
                      <option value="LOCKERCWL" key="LOCKERCWL">Jumbo Carga XL</option>
                      <option value="LOCKERBNXL" key="LOCKERBNXL">Jumbo 4</option>
                      <option value="LOCKERCNXL" key="LOCKERCNXL">Jumbo 4 Carga</option>
                      <option value="LOCKERBWXL" key="LOCKERBWXL">Jumbo 4 XL</option>
                      <option value="LOCKERCWXL" key="LOCKERCWXL">Jumbo 4 Carga XL</option>
                      <option value="LOCKERBN2XL" key="LOCKERBN2XL">Jumbo 5</option>
                      <option value="LOCKERCN2XL" key="LOCKERCN2XL">Jumbo 5 Carga</option>
                      <option value="LOCKERBW2XL" key="LOCKERBW2XL">Jumbo 5 XL</option>
                      <option value="LOCKERCW2XL" key="LOCKERCW2XL">Jumbo 5 Carga XL</option>
                      <option value="LOCKERBN3XL" key="LOCKERBN3XL">Ski</option>
                      <option value="LOCKERCN3XL" key="LOCKERCN3XL">Ski Carga</option>
                      <option value="LOCKERBW3XL" key="LOCKERBW3XL">Ski XL</option>
                      <option value="LOCKERCW3XL" key="LOCKERCW3XL">Ski Carga XL</option>
                      <option value="TUMBONAS" key="TUMBONAS">Tumbonas</option>
                      <option value="FLOTADORES" key="FLOTADORES">Flotadores</option>
                      <option value="FLOTADORESHALFDAY" key="FLOTADORESHALFDAY">Flotadores Medio Día</option>
                      <option value="FASTPASS" key="FASTPASS">Fastpass</option>
                    </Field>
                  </td>
                  <td>
                    <Field
                      name={`${member}.price`}
                      type="text"
                      component={renderInputField}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${member}.comission`}
                      type="text"
                      component={renderInputField}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${member}.stock`}
                      type="text"
                      component={renderInputField}
                    />
                  </td>
                  <td>
                    <button className="btn btn-danger mr-1" type="button"
                      onClick={() => fields.remove(index)}>
                      <i className="fas fa-trash mr-1"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="text-right">
          <button className="btn bg-vw-light text-white" type="button"
            onClick={() => fields.push({})}>
            <i className="fas fa-plus mr-1"></i>
            Añadir
          </button>
        </div>
      </div>
    )
  }

  handleShowDynamicField() {
    this.setState({
      showDynamicField: true
    });
  }

  render() {
    const { loaded, fMachines, showDynamicField } = this.state
    const { contract, customers, error, pristine, handleSubmit, submitting } = this.props

    if (!loaded || !customers || !fMachines) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <h1>
          <span className="text-vw-light">EDITAR CONTRATO DE MÁQUINA </span>
          <span className="text-vw-dark">{contract.Machine.Serial}</span>
        </h1>

        <form onSubmit={handleSubmit(this.submit)}>
          <div className="row">
            <div className="col col-12 col-lg-4">
              <Box title="Asignación" icon="arrows-alt-h">
                <Field name="Customer"
                  component={renderSelectField}
                  label="Cliente"
                  onChange={(e) => { this.setState({ customerSelected: e.target.value }) }}>
                  {customers &&
                    customers
                      .filter((customer) => customer.Type === 'GENERAL')
                      .sort((a, b) => a.Fullname.localeCompare(b.Fullname))
                      .map((customer) => (
                        <option value={customer._id} key={customer._id}>
                          {customer.Fullname}
                        </option>
                      ))}
                </Field>
                <QRZonesList customers={customers} customerSelected={this.state.customerSelected} />
                <Field name="Machine"
                  component={renderSelectField}
                  label="Máquina">
                  {fMachines.map(machine => {
                    return (
                      <option value={machine._id} key={machine._id}>
                        {machine.Serial}
                      </option>
                    )
                  })}
                </Field>
                <Field name="Location"
                  component={renderInputField}
                  type="text"
                  label="Localización (Lat, Lon)" />
              </Box>
            </div>
            <div className="col col-12 col-lg-4">
              <Box title="Funcionamiento" icon="certificate">
              <Field name="WorkingMode"
                       component={renderSelectField}
                       label="Working Mode">
                      <option value="">Selecciona un modo...</option>
                      <option value="0">MTO DIARIO</option>
                      <option value="1">MTO DIARIO + SERVICIOS</option>
                      <option value="2">MTO DIARIO TIEMPO SIN PIN</option>
                      <option value="3">MTO DIARIO TIEMPO CON PIN</option>
                      <option value="4">OTO DIARIO SIN PIN</option>
                      <option value="5">OTO DIARIO CON PIN</option>
                      <option value="6">TAQUILLAS BAQUEIRA</option>
                      <option value="7">TAQUILLAS AQUARIAZ</option>
                </Field>
                <Field name="TimeSlots"
                       component={renderSelectField}
                       label="Rango de tiempo">
                      <option value="">Selecciona un tiempo...</option>
                      <option value="30">30 minutos</option>
                      <option value="60">1 hora</option>
                      <option value="120">2 hora</option>
                      <option value="180">3 hora</option>
                      <option value="240">4 hora</option>
                </Field>
                <Field name="TimeCourtesy"
                       component={renderSelectField}
                       label="Tiempo de cortesía">
                      <option value="">Selecciona un tiempo...</option>
                      <option value="0">0 minutos</option>
                      <option value="5">5 minutos</option>
                      <option value="10">10 minutos</option>
                      <option value="15">15 minutos</option>
                      <option value="30">30 minutos</option>
                </Field>
              </Box>
            </div>
            <div className="col col-12 col-lg-4">
              <Box title="Condiciones" icon="certificate">
                <Field name="StartDate"
                  component={renderInputField}
                  type="date"
                  label="Fecha inicio" />
                <Field name="EndDate"
                  component={renderInputField}
                  type="date"
                  label="Fecha fin" />
                <Field name="Currency"
                  component={renderSelectField}
                  value="EUR"
                  label="Moneda">
                  {cc.codes().map(code => {
                    const name = cc.code(code).currency

                    return (
                      <option value={code} key={code}>
                        {`${code} - ${name}`}
                      </option>
                    )
                  })}
                </Field>
                <Field name="ConversionRate"
                  component={renderInputField}
                  type="text"
                  label="Tasa de conversión" />
                <Field name="ReportAE"
                  component={renderInputField}
                  type="checkbox"
                  label="Rerport AE Italia" />
              </Box>
            </div>
          </div>
          <Box title="Ticket" icon="receipt">
            <Field name="TicketHeader"
              component={renderInputField}
              type="text"
              label="Cabecera" />
            <Field name="TicketIVA"
              component={renderInputField}
              type="text"
              label="IVA (%)" />
            <Field name="TicketFooter"
              component={renderInputField}
              type="text"
              label="Footer" />
          </Box>
          <Box title="Artículos" icon="shopping-cart">
            <FieldArray name="Items" component={this.renderItems} />
          </Box>
          {error && <div className="alert alert-danger">{error}</div>}
          <button className="btn bg-vw-light text-white mr-1 save" type="submit"
            disabled={pristine || submitting}>
            Guardar
          </button>
        </form>
      </div>
    )
  }
}

ContractEditForm = reduxForm({
  form: 'ContractEditForm',
  onSubmitSuccess: (result, dispatch, props) => {
    props.resetMachines()
    props.push(`/contracts/${result.response._id}`)
  },
  enableReinitialize: true
})(ContractEditForm)

const mapStateToProps = state => {
  let StartDate = null
  let EndDate = null
  let Customer = null;
  let Machine = null;

  if (state.contracts.contract) {
    StartDate = state.contracts.contract.StartDate
    EndDate = state.contracts.contract.EndDate
    Customer = state.contracts.contract.Customer._id;
    Machine = state.contracts.contract.Machine._id;
  }

  return {
    contract: state.contracts.contract,
    machines: state.machines.machines,
    customers: state.customers.customers,
    fullname: state.auth.fullname,
    initialValues: {
      ...state.contracts.contract,
      StartDate: new Date(StartDate).toISOString().substring(0, 10),
      EndDate: new Date(EndDate).toISOString().substring(0, 10),
      Customer: Customer,
      Machine: Machine
    }
  }
}

const mapDispatchToProps = dispatch => ({
  fetchContract: bindActionCreators(fetchContract, dispatch),
  updateContract: bindActionCreators(updateContract, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
  resetMachines: bindActionCreators(resetMachines, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ContractEditForm)

