import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, change } from 'redux-form'
import { bindActionCreators } from 'redux'
import { SubmissionError } from 'redux-form'

import { fetchRemoteActions, addRemoteAction } from '../actions/remoteactions'
import { renderInputField, renderSelectField } from '../utils/forms'

class RemoteActionSingleAddForm extends React.Component {
  constructor(props) {
    super(props)

    this.submit = this.submit.bind(this)
  }

  submit(props) {
    // insert machine id to the query
    props.machine = this.props.machine._id
    props.executor = this.props.fullname

    return this.props.addRemoteAction(props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        // fetch remote actions so that they are refreshed
        this.props.fetchRemoteActions(this.props.machine.Serial)

        // Call closeMatrixDialog here
        this.props.closeMatrixDialog();

        return response
      })
  }

  render() {
    const { error, pristine, submitting, handleSubmit, machine,
      fetchRemoteActions } = this.props

    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-md-6 mt-2">
            <div className="flex justify-content-center">Acciones Operativas</div>
            <div className="row">
              <button className="btn btn-remoteAction bg-success text-white" onClick={() => {
                this.props.dispatch(change('RemoteActionSingleAddForm', 'code', 'SINGLE_DOOR_OPEN'));
                this.props.dispatch(change('RemoteActionSingleAddForm', 'arguments', `${this.props.lockerMatricula}`));
              }}>
                <i className="fas fa-door-open mr-1"></i>ABRIR
              </button>
              <button className="btn btn-remoteAction bg-vw-red text-white" onClick={() => {
                this.props.dispatch(change('RemoteActionSingleAddForm', 'code', 'SINGLE_UNRENT'));
                this.props.dispatch(change('RemoteActionSingleAddForm', 'arguments', `${this.props.lockerMatricula}`));
              }}>
                <i className="fas fa-link mr-1"></i>DESALQUILAR
              </button>
              <button className="btn btn-remoteAction bg-vw-purple text-white" onClick={() => {
                this.props.dispatch(change('RemoteActionSingleAddForm', 'code', 'SINGLE_BLOCKLATER'));
                this.props.dispatch(change('RemoteActionSingleAddForm', 'arguments', `${this.props.lockerMatricula}`));
              }}>
                <i className="fas fa-qrcode mr-1"></i>RESERVA QR/PDA
              </button>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 mt-2">
            <div className="flex justify-content-center">Acciones Críticas</div>
            <div className="row">
              <button className="btn btn-remoteAction bg-vw-red text-white" onClick={() => {
                this.props.dispatch(change('RemoteActionSingleAddForm', 'code', 'SINGLE_NIGHTSCAN'));
                this.props.dispatch(change('RemoteActionSingleAddForm', 'arguments', `${this.props.lockerMatricula}`));
              }}>
                <i className="fas fa-globe mr-1"></i>VINCULAR
              </button>
              <button className="btn btn-remoteAction bg-vw-red text-white" onClick={() => {
                this.props.dispatch(change('RemoteActionSingleAddForm', 'code', 'SINGLE_KERONG_PROGRAM_OTA'));
                this.props.dispatch(change('RemoteActionSingleAddForm', 'arguments', `${this.props.lockerMatricula}`));
              }}>
                <i className="fas fa-microchip mr-1"></i>PROGRAM OTA
              </button>
              <button className="btn btn-remoteAction bg-vw-red text-white" onClick={() => {
                this.props.dispatch(change('RemoteActionSingleAddForm', 'code', 'SINGLE_KERONG_BOOTLOADER_OTA'));
                this.props.dispatch(change('RemoteActionSingleAddForm', 'arguments', `${this.props.lockerMatricula}`));
              }}>
                <i className="fas fa-microchip mr-1"></i>BOOTLOADER OTA
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col mt-2">
            <div className="flex justify-content-center">Formulario</div>
            <form onSubmit={handleSubmit(this.submit)} className="mb-3 mt-3">
              <div className="align-items-center justify-content-center">
                <Field name="code" component={renderSelectField}>
                  <option value="">Selecciona una acción...</option>
                  <option value="REBOOT">Reiniciar máquina</option>
                  <option value="SINGLE_DOOR_OPEN">Abrir</option>
                  <option value="SINGLE_UNRENT">Desalquilar</option>
                  <option value="SINGLE_BLOCKLATER">Reserva QR/PDA</option>
                  <option value="SINGLE_NIGHTSCAN">Vincular Individual</option>
                  <option value="SINGLE_KERONG_PROGRAM_OTA">OTA Programa Individual</option>
                  <option value="SINGLE_KERONG_BOOTLOADER_OTA">OTA Bootloader Individual</option>
                  <option value="SINGLE_FACTORY_RESET">Reset de fábrica Individual</option>
                  <option value="SINGLE_MEMORY_DEBUG">Lectura de memória en Debug</option>
                </Field>
                <Field name="arguments"
                  type="text"
                  component={renderInputField}
                  placeholder="Argumentos" />
              </div>
              <div className="form-row align-items-center flex justify-content-center">
                <div className="col-md-12 align-items-center flex justify-content-center">
                  <button className="btn bg-vw-light text-white mr-1" type="submit"
                    disabled={pristine || submitting}>
                    <i className="fas fa-bolt mr-1"></i>Ejecutar
                  </button>
                </div>
              </div>
              <div className="form-row">
                {error && <div className="alert alert-danger">{error}</div>}
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

RemoteActionSingleAddForm = reduxForm({
  form: 'RemoteActionSingleAddForm',
})(RemoteActionSingleAddForm)

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
})

const mapDispatchToProps = dispatch => ({
  addRemoteAction: bindActionCreators(addRemoteAction, dispatch),
  fetchRemoteActions: bindActionCreators(fetchRemoteActions, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RemoteActionSingleAddForm)

