import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchObjectives } from '../actions/objectives'
import { fetchArqueos } from '../actions/arqueos'
import Box from '../components/Box'
import ObjectiveList from '../components/ObjectiveList'
import ErrorMessage from '../components/ErrorMessage'

import ObjectivesFilterForm from '../forms/ObjectivesFilterForm'

class ObjectivesView extends React.Component {
  constructor(props) {
    super(props)

  }

  componentDidMount() {
    const today = new Date();
    const currentYear = today.getFullYear();

    const yearStart = new Date(currentYear, 0, 1);
    const formattedStartDate =
      `${yearStart.getFullYear()}-${(yearStart.getMonth() + 1).toString().padStart(2, '0')}-${yearStart.getDate().toString().padStart(2, '0')}`;
    const yearEnd = new Date(currentYear + 1, 0, 0);
    const formattedEndDate =
      `${yearEnd.getFullYear()}-${(yearEnd.getMonth() + 1).toString().padStart(2, '0')}-${yearEnd.getDate().toString().padStart(2, '0')}`;

    this.props.fetchObjectives('', today)
    this.props.fetchArqueos('', '', formattedStartDate, formattedEndDate, '');
  }

  calculateTotalObjective(objectives) {

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const weeks = ['W1', 'W2', 'W3', 'W4', 'W5', 'W6'];

    let totalObjectives = 0;

    objectives.forEach(objective => {
      {
        months.map((month) => {
          weeks.map(week => (
            totalObjectives += objective[month][week]

          ))
        }
        )
      }
    });

    return totalObjectives
  }

  calculateTotalReal() {

    let totalReal = 0;

    {
      this.props.arqueos.map((arqueo) => {
        if (arqueo.ContractType != "SALE") {
          totalReal += this.calculatePriceWithoutTax(arqueo.SalesTotal, arqueo.Tax)
        }
      }
      )
    }

    return totalReal
  }

  calculatePriceWithoutTax(priceWithTax, taxRate) {
    // Convert tax rate from percentage to decimal
    const taxMultiplier = 1 + taxRate / 100;

    // Calculate price without tax and round to two decimal places
    const priceWithoutTax = (priceWithTax / taxMultiplier).toFixed(2);

    // Convert the string back to a number
    return parseFloat(priceWithoutTax);
  }

  progressBar(actual, objective) {

    const percentage = Math.min((actual / objective) * 100, 100);

    let color = "bg-warning"

    if (percentage < 50) {
      color = "bg-danger"
    } else if (percentage === 100) {
      color = "bg-success"
    }

    return (
      <div className="progress-container">
        <div className={`progress-bar ${color}`} style={{ width: `${percentage}%` }}></div>
        <div className="progress-percentage">{percentage.toFixed(2)}%</div>
      </div>
    );
  }

  renderTotalObjectives() {
    const { arqueos, objectives, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!objectives || loading || !arqueos) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    if (objectives.length === 0) {
      return (
        <div className="alert alert-warning" role="alert">
          No hay objetivos disponibles para mostrar. Por favor, crea nuevos objetivos para el año actual.
        </div>
      );
    }

    const date = (new Date(objectives[0].TimeStamp)).getFullYear()

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Cliente</th>
              <th scope="col">Año</th>
              <th scope="col">Real</th>
              <th scope="col">Objetivo</th>
              <th scope="col">Progreso</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Todos los clientes</td>
              <td>{date}</td>
              <td>{this.calculateTotalReal().toFixed(2)} €</td>
              <td>{this.calculateTotalObjective(objectives).toFixed(2)} €</td>
              <td>{this.progressBar(this.calculateTotalReal(), this.calculateTotalObjective(objectives))}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderObjectives() {
    const { arqueos, objectives, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!objectives || loading || !arqueos) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    if (objectives.length === 0) {
      return (
        <div className="alert alert-warning" role="alert">
          No hay objetivos disponibles para mostrar. Por favor, crea nuevos objetivos para el año actual.
        </div>
      );
    }

    return (
      <ObjectiveList objectives={objectives} arqueos={arqueos} />
    )
  }

  render() {
    const { role } = this.props

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">OBJETIVOS DE </span>
              <span className="text-vw-light">VENTA</span>
            </h1>
          </div>
          {role === 'ADMIN' &&
            <div className="col-xs-12 col-sm-6 col-md-6 align-items-center d-flex justify-content-center">
              <div className="col d-flex justify-content-end">
                <Link to="/objectives/create">
                  <button type="button" className="btn bg-vw-light text-white">
                    <i className="fas fa-plus mr-1"></i>Crear Objetivos
                  </button>
                </Link>
              </div>
            </div>
          }
        </div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-4 col-md-4">
            <Box title="Filtro" icon="filter">
              <ObjectivesFilterForm />
            </Box>
          </div>
          <div className="col-xs-12 col-sm-8 col-md-8">
            {this.renderTotalObjectives()}
            {this.renderObjectives()}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  objectives: state.objectives.objectives,
  arqueos: state.arqueos.arqueos,
  error: state.objectives.error,
  loading: state.arqueos.loading,
  role: state.auth.role,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchObjectives: bindActionCreators(fetchObjectives, dispatch),
  fetchArqueos: bindActionCreators(fetchArqueos, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ObjectivesView)

