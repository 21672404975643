import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { Field, FieldArray, reduxForm, SubmissionError } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Box from '../components/Box';
import { addModule } from '../actions/modules';
import { renderInputField, renderTextAreaField, renderSelectField } from '../utils/forms'

import { QrReader } from 'react-qr-reader';

class ModuleAddForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      legacyMode: true,
      result: 'Escaneando...',
      qrModalOpen: false,
      qrConfirmModalOpen: false,
      qrFullModalOpen: false,
      qrScanActivated: false,
      currentPairingIndex: 0
    };

    this.submit = this.submit.bind(this);
    this.qrModalOpenToggle = this.qrModalOpenToggle.bind(this);
    this.qrConfirmModalOpenToggle = this.qrConfirmModalOpenToggle.bind(this);
    this.addPairingLine = this.addPairingLine.bind(this);
    this.finishPairing = this.finishPairing.bind(this);
  }

  submit(props) {
    return this.props.addModule(props).then(response => {
      if (response.error) {
        throw new SubmissionError({
          _error: response.error.message,
          ...response.error.fields
        });
      }

      return response;
    });
  }

  qrModalOpenToggle() {
    this.setState(prevState => ({
      qrModalOpen: !prevState.qrModalOpen,
      qrScanActivated: !prevState.qrModalOpen
    }));

    if (!this.state.qrModalOpen) {
      this.setState({ qrScanActivated: true });
    } else {
      this.setState({ qrScanActivated: false });
    }
  }

  qrConfirmModalOpenToggle() {
    this.setState(prevState => ({ qrConfirmModalOpen: !prevState.qrConfirmModalOpen }));
  }

  addPairingLine(fields) {
    fields.push({});
    this.setState(prevState => ({ currentPairingIndex: prevState.currentPairingIndex + 1, qrScanActivated: true, result: null }));
  }

  finishPairing() {
    this.setState({ qrModalOpen: false, qrScanActivated: false });
  }

  renderQRResult(fields) {
    return (
      <div>
        <div className="mt-5 mb-5 text-center">
          <span>SN escaneado</span>
          <br></br>
          <h5>{this.state.result}</h5>
        </div>
        <div className="flex justify-content-around mt-5">
          <Button color="danger" onClick={this.finishPairing}>Finalizar</Button>
          {!this.state.qrScanActivated &&
            <Button color="primary" onClick={() => this.addPairingLine(fields)}>Confirmar y seguir</Button>
          }
        </div>
      </div>
    );
  }

  renderQRScan(fields) {
    const { currentPairingIndex } = this.state;

    return (
      <div>
        <QrReader
          onResult={(result, error) => {
            if (!!result && this.state.qrScanActivated && fields.get(currentPairingIndex)) {
              fields.get(currentPairingIndex).SN = result?.text.slice(-8);
              this.setState({ result: result?.text.slice(-8), qrScanActivated: false });
            }

            if (!!error) {
              console.info(error);
            }
          }}
          style={{ width: '100%' }}
          constraints={{ facingMode: 'environment' }}
        />
        <div className="flex justify-content-around mt-5">
          <Button color="danger" onClick={this.finishPairing}>Finalizar</Button>
        </div>
      </div>
    );
  }

  renderPairing({ fields }) {
    return (
      <div>
        <div className="table-responsive">
          <table className="table table-sm table-stripped">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Index</th>
                <th scope="col">Número de serie</th>
                <th scope="col">PIN</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {fields.map((registerlocker, index) => (
                <tr key={index}>
                  <td>
                    {index + 1}
                  </td>
                  <td>
                    <Field
                      name={`${registerlocker}.SN`}
                      type="text"
                      component={renderInputField}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${registerlocker}.Pin`}
                      type="text"
                      component={renderInputField}
                    />
                  </td>
                  <td>
                    <button className="btn btn-danger mr-1" type="button"
                      onClick={() => fields.remove(index)}>
                      <i className="fas fa-trash mr-1"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="text-right">
          <button className="btn bg-vw-light text-white" type="button"
            onClick={() => fields.push({})}>
            <i className="fas fa-plus mr-1"></i>
            Añadir
          </button>
        </div>
        <div>
          <Modal isOpen={this.state.qrModalOpen}
            toggle={this.qrModalOpenToggle}
            className="centered-modal">
            <ModalHeader toggle={this.qrModalOpenToggle}>
              Escaneando posición {this.state.currentPairingIndex + 1}...
            </ModalHeader>
            <ModalBody>
              {this.state.qrScanActivated ? (
                this.renderQRScan(fields)
              ) : (
                this.renderQRResult(fields)
              )}
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }

  render() {
    const { error, pristine, submitting, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">NUEVO </span>
              <span className="text-vw-light">MÓDULO</span>
            </h1>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 align-items-center d-flex justify-content-center">
            <div className="col d-flex justify-content-center">
              <button className="btn btn-success mr-1" type="button"
                onClick={() => this.qrModalOpenToggle()}>
                <i className="fas fa-qrcode mr-1"></i> INICIAR LECTOR QR
              </button>
            </div>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <div className="row">
          <div className="col">
            <Box title="Configuración" icon="cubes">
              <div className="row">
                <div className="col">
                  <Field name="Code"
                    type="text"
                    component={renderInputField}
                    label="Número de Serie" />
                </div>
                <div className="col">
                  <Field name="Mode"
                    component={renderSelectField}
                    label="Modo">
                    <option value="">Selecciona un modo...</option>
                    <option value="B">Módulo básico</option>
                    <option value="C">Módulo de carga</option>
                  </Field>
                </div>
                <div className="col">
                  <Field name="Wide"
                    component={renderSelectField}
                    label="Ancho">
                    <option value="">Selecciona un ancho...</option>
                    <option value="N">Normal</option>
                    <option value="W">Ampliado (Wide)</option>
                  </Field>
                </div>
                <div className="col">
                  <Field name="Espressif"
                    component={renderSelectField}
                    label="Espressif">
                    <option value="">Selecciona un espressif...</option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                  </Field>
                </div>
                <div className="col">
                  <Field name="Gender"
                    component={renderSelectField}
                    label="Género">
                    <option value="">Selecciona un género...</option>
                    <option value="">Sin género</option>
                    <option value="M">Masculino</option>
                    <option value="F">Femenino</option>
                  </Field>
                </div>
                <div className="col">
                  <Field name="HardType"
                    component={renderSelectField}
                    label="Cierre">
                    <option value="">Selecciona un modelo...</option>
                    <option value="2">Pincho</option>
                    <option value="3">Slide</option>
                  </Field>
                </div>
              </div>
              <FieldArray name="RegisterLockers" component={this.renderPairing.bind(this)} />
            </Box>
          </div>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <button className="btn bg-vw-light text-white mr-1 save" type="submit"
          disabled={pristine || submitting}>
          Guardar
        </button>
      </form>
    );
  }
}

ModuleAddForm = reduxForm({
  form: 'ModuleAddForm',
  enableReinitialize: true,
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/modules/${result.response.Code}`);
  },
  initialValues: {
    RegisterLockers: [{}] // This will add an initial empty field
  }
})(ModuleAddForm);

const mapDispatchToProps = dispatch => ({
  addModule: bindActionCreators(addModule, dispatch),
  push: bindActionCreators(push, dispatch)
});

export default connect(() => ({}), mapDispatchToProps)(ModuleAddForm);
