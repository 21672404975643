import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { history } from '../store'


class RentalsFinishedList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        code: '',
        operator: '',
        status: '',
        name: '',
        phone: '',
        mail: '',
        type: '',
        start_date: '',
        end_date: '',
        pay_date: '',
        lockerZone: '',  // New filter for Locker Zone
        lockerMatricula: ''  // New filter for Locker Matriculas
      },
    };
  }

  goTo(id, e) {
    history.push(`/rentals/${id}`)
  }

  handleFilterChange(filterKey, value) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [filterKey]: value,
      },
    }));
  }

  getStatus(status) {

    let color = "";
    let name = "";

    if (status === 'COMPLETE') {
      color = "success"
      name = "COMPLETADO"
    } else if (status === 'ONPROCESS') {
      color = "info"
      name = "EN PROCESO"
    } else if (status === 'PENDING') {
      color = "warning"
      name = "EN ESPERA"
    } else if (status === 'ERROR') {
      color = "secondary"
      name = "ERROR"
    } else if (status === 'CANCELED') {
      color = "danger"
      name = "CANCELADA"
    } else if (status === 'PRERENT') {
      color = "dark"
      name = "PRE-RESERVA"
    }

    return (
      <div className={`badge badge-${color}`}>
        {name}
      </div>
    )
  }

  getType(type) {

    let name = "";

    if (type === 'RENTAL') {
      name = "ALQUILER"
    } else if (type === 'SEASON') {
      name = "TEMPORADA"
    } else if (type === 'COLLECT') {
      name = "CLICK & COLLECT"
    } else if (type === 'WHITELIST') {
      name = "LISTAS BLANCAS"
    } else if (type === 'COUNTERINVOICE') {
      name = "CONTRA FACTURA"
    }

    return name
  }

  getColorType(type) {

    let name = "";

    if (type === 'RENTAL') {
      name = "warning"
    } else if (type === 'SEASON') {
      name = "primary"
    } else if (type === 'COLLECT') {
      name = "success"
    } else if (type === 'WHITELIST') {
      name = "secondary"
    } else if (type === 'COUNTERINVOICE') {
      name = "danger"
    }

    return name
  }

  getZoneName(zone) {

    switch (zone) {
      case 0:
        return 'GENERAL';
      case 1:
        return 'BAQUEIRA';
      case 2:
        return 'BERET';
      case 3:
        return 'BONAIGUA';
      case 4:
        return 'RUDA';
      case 5:
        return 'BOSQUE';
      default:
        return 'NONE';
    }
  }

  getZoneColor(zone) {

    switch (zone) {
      case 0:
        return 'bg-primary';
      case 1:
        return 'bg-white';
      case 2:
        return 'bg-success';
      case 3:
        return 'bg-info';
      case 4:
        return 'bg-warning';
      case 5:
        return 'bg-secondary';
      default:
        return 'bg-danger';
    }
  }

  render() {
    const { filters } = this.state;
    const { rentals } = this.props;

    // Apply the filters to filter the rental array
    const filteredRentals = rentals
      .filter(rental => (rental.Status === 'CANCELED' || rental.Status === 'COMPLETE') && rental.Name !== 'PRERENT')
      .filter(rental => {
        const { code, operator, status, type, start_date, end_date, pay_date, lockerZone, lockerMatricula } = filters;

        const clienteInfo = `${rental.Name || ''} ${rental.Phone || ''} ${rental.Email || ''} ${rental.PrerentName || ''} ${rental.PrerentPhone || ''} ${rental.PrerentEmail || ''}`.toLowerCase();

        const codeInfo = `${rental.Code || ''} ${rental.OriginalInvoice || ''}`.toLowerCase();
        const normalizedCode = code ? code.toLowerCase() : '';

        // Apply the Serial filter
        if (code && !codeInfo.includes(normalizedCode)) {
          return false;
        }

        // Apply the Cliente filter (Name, Phone, Email combined)
        if (filters.name && !clienteInfo.includes(filters.name.toLowerCase())) {
          return false;
        }

        // Apply the Operator or Contract Zone filter based on category
        if (operator) {
          // Apply the Operator filter
          if (!rental.Operator || !rental.Operator.Name.toLowerCase().includes(operator.toLowerCase())) {
            return false;
          }
        }

        // Apply the Serial filter
        if (
          type &&
          (!rental.Type ||
            !rental.Type.toLowerCase().includes(type.toLowerCase()))
        ) {
          return false;
        }

        // Apply the Status filter
        if (
          status &&
          (!rental.Status ||
            !rental.Status.toLowerCase().includes(status.toLowerCase()))
        ) {
          return false;
        }

        // Apply the end_date filter
        if (pay_date && new Date(rental.TimeStamp).toLocaleDateString() !== new Date(pay_date).toLocaleDateString()) {
          return false;
        }

        // Apply the end_date filter
        if (start_date && new Date(rental.StartDate).toLocaleDateString() !== new Date(start_date).toLocaleDateString()) {
          return false;
        }

        // Apply the end_date filter
        if (end_date && new Date(rental.FinishDate).toLocaleDateString() !== new Date(end_date).toLocaleDateString()) {
          return false;
        }

        // Apply the Locker Zone filter
        if (lockerZone && rental.Machine?.Contract?.Zone?.toString() !== lockerZone) return false;

        // Apply the Locker Matriculas filter
        if (lockerMatricula) {
          const matriculaMatch = rental.LockerMatricula.some(matricula =>
            (Number(matricula) + rental.Machine.FirstIndex - 1).toString() === lockerMatricula
          );
          if (!matriculaMatch) return false;
        }

        // If all conditions pass, include the machine in the filtered list
        return true;
      });

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Código de reserva</th>
              <th scope="col">Tipo</th>
              <th scope="col">Pago</th>
              <th scope="col">Inicio</th>
              <th scope="col">Fin</th>
              <th scope="col">Agregador</th>
              <th scope="col">Cliente</th>
              <th scope="col">Taquillas</th>
              <th scope="col">Estado</th>
            </tr>
            <tr>
              <th scope="col">
                <input
                  type="text"
                  placeholder="Código"
                  value={filters.code}
                  onChange={e => this.handleFilterChange('code', e.target.value)}
                />
              </th>
              <th scope="col">
                <select
                  value={filters.type}
                  onChange={e => this.handleFilterChange('type', e.target.value)}
                >
                  <option value="">Todos</option>
                  <option value="RENTAL">ALQUILER</option>
                  <option value="SEASON">TEMPORADA</option>
                  <option value="COLLECT">CLICK & COLLECT</option>
                  <option value="WHITELIST">LISTAS BLANCAS</option>
                  <option value="COUNTERINVOICE">CONTRA FACTURA</option>
                </select>
              </th>
              <th scope="col">
                <input
                  type="date"
                  value={filters.pay_date}
                  onChange={e => this.handleFilterChange('pay_date', e.target.value)}
                />
              </th>
              <th scope="col">
                <input
                  type="date"
                  value={filters.start_date}
                  onChange={e => this.handleFilterChange('start_date', e.target.value)}
                />
              </th>
              <th scope="col">
                <input
                  type="date"
                  value={filters.end_date}
                  onChange={e => this.handleFilterChange('end_date', e.target.value)}
                />
              </th>
              <th scope="col">
                <input
                  type="text"
                  placeholder='Agregador'
                  value={filters.operator}
                  onChange={e => this.handleFilterChange('operator', e.target.value)}
                />
              </th>
              <th scope="col">
                <input
                  type="text"
                  placeholder='Cliente'
                  value={filters.name}
                  onChange={e => this.handleFilterChange('name', e.target.value)}
                />
              </th>
              <th scope="col">
                <select
                  value={filters.lockerZone}
                  onChange={e => this.handleFilterChange('lockerZone', e.target.value)}
                >
                  <option value="">Todos</option>
                  <option value="0">GENERAL</option>
                  <option value="1">BAQUEIRA</option>
                  <option value="2">BERET</option>
                  <option value="3">BONAIGUA</option>
                  <option value="4">RUDA</option>
                  <option value="5">BOSQUE</option>
                </select>
                <input
                  type="text"
                  placeholder="Matrícula"
                  value={filters.lockerMatricula}
                  onChange={e => this.handleFilterChange('lockerMatricula', e.target.value)}
                />
              </th>
              <th scope="col">
                <select
                  value={filters.status}
                  onChange={e => this.handleFilterChange('status', e.target.value)}
                >
                  <option value="">Todos</option>
                  <option value="PENDING">EN ESPERA</option>
                  <option value="ONPROCESS">EN PROCESO</option>
                  <option value="COMPLETE">COMPLETADAS</option>
                  <option value="ERROR">EN ERROR</option>
                  <option value="CANCELED">CANCELADAS</option>
                  <option value="PRERENT">PRE-RESERVAS</option>
                </select>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredRentals
              .sort((a, b) => {
                // Compare StartDate as timestamps to sort by nearest to farthest date
                const dateA = new Date(a.StartDate).getTime();
                const dateB = new Date(b.StartDate).getTime();
                return dateA - dateB; // Ascending order (nearest date first)
              })
              .map(rental => (
                <tr key={rental._id}
                  onClick={e => this.goTo(rental._id, e)}>
                  <td>
                    <div className={rental.Type === "COUNTERINVOICE" ? 'text-danger' : ''}>{rental.Code}</div>
                    {rental.Type === 'COLLECT' && <div className="text-success">{`Click&Collect: ${rental.ClickCode}`}</div>}
                    {(rental.Type === 'COUNTERINVOICE' || rental.OriginalInvoice) && <div>{`Original: ${rental.OriginalInvoice}`}</div>}
                  </td>
                  <td className={`bg-${this.getColorType(rental.Type)}`}>{this.getType(rental.Type)}</td>
                  <td>{(new Date(rental.TimeStamp)).toLocaleString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' })}</td>
                  <td>{(new Date(rental.StartDate)).toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</td>
                  <td>{(new Date(rental.FinishDate)).toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</td>
                  <td>{rental.Operator && rental.Operator.Name || 'None'}</td>
                  <td>
                    <div>{rental.Name === "PRERENT" ? (rental.PrerentName || 'PRERENT') : rental.Name}</div>
                    <div>{rental.Phone === "PRERENT" ? (rental.PrerentPhone || 'PRERENT') : rental.Phone}</div>
                    <div>{rental.Email === "PRERENT" ? (rental.PrerentEmail || 'PRERENT') : rental.Email}</div>
                  </td>
                  <td>
                    <div className={`price-entry ${this.getZoneColor(rental.Machine?.Contract?.Zone)}`}>
                      {this.getZoneName(rental.Machine?.Contract?.Zone)}
                    </div>

                    {rental.LockerMatricula.map((matricula, index) =>
                      <div className='lockerID mt-2 mb-2'>
                        {Number(matricula) + rental.Machine.FirstIndex - 1}
                      </div>
                    )}
                  </td>
                  <td>
                    {this.getStatus(rental.Status)}
                  </td>
                </tr>
              )
              )}
          </tbody>
        </table>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
  category: state.auth.category
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(RentalsFinishedList)



