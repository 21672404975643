import React, { useState, useEffect } from "react";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import * as webLLM from "deep-chat-web-llm";
import hljs from "highlight.js";
import { DeepChat } from "deep-chat-react";

function FloatingChat() {
  const [chatVisible, setChatVisible] = useState(false);

  // Define FAQ data
  const faqData = [
    {
      category: "Problemas con pantallas",
      issues: [
        {
          problem: "Pantalla negra, no enciende, o no responde al tacto.",
          solution:
            "Desconectar y reconectar la máquina, reemplazo de pantalla, revisión de conexiones.",
        },
      ],
    },
    {
      category: "Problemas con los métodos de pago",
      issues: [
        {
          problem: "No acepta monedas o billetes",
          solution: "Limpieza del validador o reemplazo si está dañado.",
        },
        {
          problem: "Devolución incorrecta de cambio.",
          solution: "Revisión y ajuste de los sistemas de devolución.",
        },
      ],
    },
    // Add more FAQ categories and issues here...
  ];

  useEffect(() => {
    // Initialize highlight.js
    if (!window.hljs) {
      window.hljs = hljs;
    }

    // Initialize Azure Speech SDK
    if (!window.SpeechSDK) {
      window.SpeechSDK = sdk;
    }

    // Initialize Web LLM
    if (!window.webLLM) {
      window.webLLM = webLLM;
    }
  }, []);

  // Function to handle user queries
  const handleUserQuery = (query) => {
    // Loop through FAQ data and find a matching response
    for (const category of faqData) {
      for (const issue of category.issues) {
        if (query.toLowerCase().includes(issue.problem.toLowerCase())) {
          return issue.solution;
        }
      }
    }

    // Fallback response if no match is found
    return "Lo siento, no tengo una respuesta adecuada para eso.";
  };

  return (
    <div>
      {/* Floating Chat Button */}
      <button
        onClick={() => setChatVisible(!chatVisible)}
        className="chatButton"
      >
        💬
      </button>

      {chatVisible && (
        <div
          style={{
            position: "fixed",
            bottom: "150px",
            right: "25px",
            width: "100%",
            maxWidth: "400px",
            background: "#fff",
            border: "1px solid #ddd",
            borderRadius: "10px",
            overflow: "hidden",
            zIndex: 2000,
          }}
        >
          {/* DeepChat Component */}
          <DeepChat
            demo={false} // Disable demo mode
            textInput={{ placeholder: { text: "Describe tu problema..." } }}
            style={{ width: "100%", height: "100%", border: "none" }}
            history={[]}
            onMessageSent={(event) => {
              // Get user query from event
              const userQuery = event.detail.message;

              // Get response from FAQ logic
              const response = handleUserQuery(userQuery);

              // Add response to the chat
              event.target.addMessage({ text: response, sender: "ai" });
            }}
          />
        </div>
      )}
    </div>
  );
}

export default FloatingChat;
