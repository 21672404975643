import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Field, FieldArray, reduxForm, SubmissionError } from 'redux-form'

import Box from '../components/Box'
import { addMachine } from '../actions/machines'
import { renderInputField, renderSelectField, renderTextAreaField } from '../utils/forms'

class MachineAddForm extends React.Component {
       constructor(props) {
              super(props)

              this.submit = this.submit.bind(this)
       }

       submit(props) {

              props.Executor = this.props.fullname

              return this.props.addMachine(props)
                     .then(response => {
                            if (response.error) {
                                   throw new SubmissionError({
                                          _error: response.error.message,
                                          ...response.error.fields
                                   })
                            }

                            return response
                     })
       }

       renderPairingSecurity(props) {
              const { fields } = props

              return (
                     <div>
                            <div className="table-responsive">
                                   <table className="table table-sm table-stripped">
                                          <thead className="thead-dark">
                                                 <tr>
                                                        <th scope="col">Tipo</th>
                                                        <th scope="col">Número de serie</th>
                                                        <th scope="col">PIN</th>
                                                        <th scope="col"></th>
                                                 </tr>
                                          </thead>
                                          <tbody>
                                                 {fields.map((securitylocker, index) => (
                                                        <tr key={index}>
                                                               <td>
                                                                      <Field name={`${securitylocker}.Type`}
                                                                             component={renderSelectField}>
                                                                             <option value="">Selecciona un tipo...</option>
                                                                             <option value="COINBOX">COINBOX</option>
                                                                             <option value="BILLBOX">BILLBOX</option>
                                                                             <option value="CHANGEBOX">CHANGEBOX</option>
                                                                             <option value="SAFETYBOX">SAFETYBOX</option>
                                                                      </Field>
                                                               </td>
                                                               <td>
                                                                      <Field
                                                                             name={`${securitylocker}.SN`}
                                                                             type="text"
                                                                             component={renderInputField}
                                                                      />
                                                               </td>
                                                               <td>
                                                                      <Field
                                                                             name={`${securitylocker}.Pin`}
                                                                             type="text"
                                                                             component={renderInputField}
                                                                      />
                                                               </td>
                                                               <td>
                                                                      <button className="btn btn-danger mr-1" type="button"
                                                                             onClick={() => fields.remove(index)}>
                                                                             <i className="fas fa-trash mr-1"></i>
                                                                      </button>
                                                               </td>
                                                        </tr>
                                                 ))}
                                          </tbody>
                                   </table>
                            </div>
                            <div className="text-right">
                                   <button className="btn bg-vw-light text-white" type="button"
                                          onClick={() => fields.push({})}>
                                          <i className="fas fa-plus mr-1"></i>
                                          Añadir
                                   </button>
                            </div>
                     </div>
              )
       }

       renderLanguages(props) {
              const { fields } = props

              let languageCounter = 0;

              return (
                     <div>
                            <div className="table-responsive">
                                   <table className="table table-sm table-stripped">
                                          <tbody>
                                                 {fields.map((registerlanguage, index) => (
                                                        <tr key={index}>
                                                               <td>
                                                                      {(languageCounter++) + 1}
                                                               </td>
                                                               <td>
                                                                      <Field name={`${registerlanguage}.Language`}
                                                                             component={renderSelectField}>
                                                                             <option value="">Selecciona un idioma...</option>
                                                                             <option value="es_ES">ESPAÑOL</option>
                                                                             <option value="en_EN">ENGLISH</option>
                                                                             <option value="fr_FR">FRANÇAIS</option>
                                                                             <option value="it_IT">ITALIANO</option>
                                                                             <option value="gr_GR">DEUTSCH</option>
                                                                             <option value="cz_CZ">ČEŠTINA</option>
                                                                             <option value="cat_CAT">CATALÀ</option>
                                                                             <option value="pt_PT">PORTUGUESE</option>
                                                                             <option value="nw_NW">NORSK</option>
                                                                             <option value="nl_NL">NEDERLAND</option>
                                                                      </Field>
                                                               </td>
                                                               <td>
                                                                      <button className="btn btn-danger mr-1" type="button"
                                                                             onClick={() => fields.remove(index)}>
                                                                             <i className="fas fa-trash mr-1"></i>
                                                                      </button>
                                                               </td>
                                                        </tr>
                                                 ))}
                                          </tbody>
                                   </table>
                            </div>
                            {languageCounter < 3 &&
                                   <div className="text-right">
                                          <button className="btn bg-vw-light text-white" type="button"
                                                 onClick={() => fields.push({})}>
                                                 <i className="fas fa-plus mr-1"></i>
                                                 Añadir
                                          </button>
                                   </div>
                            }
                     </div>
              )
       }

       renderModules(props) {
              const { fields } = props

              return (
                     <div>
                            <div className="table-responsive">
                                   <table className="table table-sm table-stripped">
                                          <thead className="thead-dark">
                                                 <tr>
                                                        <th scope="col">Número de serie</th>
                                                        <th scope="col"></th>
                                                 </tr>
                                          </thead>
                                          <tbody>
                                                 {fields.map((module, index) => (

                                                        <tr key={index}>
                                                               <td>
                                                                      <Field
                                                                             name={`${module}.serialNumber`}
                                                                             type="text"
                                                                             component={renderInputField}
                                                                      />
                                                               </td>
                                                               <td>
                                                                      <button className="btn btn-danger mr-1" type="button"
                                                                             onClick={() => fields.remove(index)}>
                                                                             <i className="fas fa-trash mr-1"></i>
                                                                      </button>
                                                               </td>
                                                        </tr>
                                                 ))}
                                          </tbody>
                                   </table>
                            </div>
                            <div className="text-right">
                                   <button className="btn bg-vw-light text-white" type="button"
                                          onClick={() => fields.push({})}>
                                          <i className="fas fa-plus mr-1"></i>
                                          Añadir
                                   </button>
                            </div>
                     </div>
              )
       }

       renderPaymentDevices(props) {
              const { fields } = props

              return (
                     <div>
                            <div className="table-responsive">
                                   <table className="table table-sm table-stripped">
                                          <thead className="thead-dark">
                                                 <tr>
                                                        <th scope="col">Tipo</th>
                                                        <th scope="col">Dispositivo</th>
                                                        <th scope="col"></th>
                                                 </tr>
                                          </thead>
                                          <tbody>
                                                 {fields.map((device, index) => (

                                                        <tr key={index}>
                                                               <td>
                                                                      <Field name={`${device}.Type`}
                                                                             component={renderSelectField}
                                                                             label="Tipo">
                                                                             <option value="">Selecciona un tipo...</option>
                                                                             <option value="TPV">TPV</option>
                                                                             <option value="CASH">CASH</option>
                                                                             <option value="RFID">RFID</option>
                                                                      </Field>
                                                               </td>
                                                               <td>
                                                                      <Field name={`${device}.Device`}
                                                                             component={renderSelectField}
                                                                             label="Dispositivo">
                                                                             <option value="">Selecciona un dispositivo...</option>
                                                                             <option value="PAYTEF">PAYTEF</option>
                                                                             <option value="SPIRE">SPIRE</option>
                                                                             <option value="U1000">U1000</option>
                                                                             <option value="Q5000">Q5000</option>
                                                                             <option value="Q6000">Q6000</option>
                                                                             <option value="NV9USB">NV9USB</option>
                                                                             <option value="COINCO">COINCO</option>
                                                                             <option value="HOPPER">HOPPER</option>
                                                                             <option value="D2S">D2S</option>
                                                                      </Field>
                                                               </td>
                                                               <td>
                                                                      <button className="btn btn-danger mr-1" type="button"
                                                                             onClick={() => fields.remove(index)}>
                                                                             <i className="fas fa-trash mr-1"></i>
                                                                      </button>
                                                               </td>
                                                        </tr>
                                                 ))}
                                          </tbody>
                                   </table>
                            </div>
                            <div className="text-right">
                                   <button className="btn bg-vw-light text-white" type="button"
                                          onClick={() => fields.push({})}>
                                          <i className="fas fa-plus mr-1"></i>
                                          Añadir
                                   </button>
                            </div>
                     </div>
              )
       }

       render() {
              const { error, pristine, submitting, handleSubmit } = this.props

              return (
                     <form onSubmit={handleSubmit(this.submit)}>
                            <h1>
                                   <span className="text-vw-dark">MÁQUINA </span>
                                   <span className="text-vw-light">NUEVA</span>
                            </h1>

                            <div className="row mb-5">
                                   <div className="col-xs-12 col-md-2">
                                          <Box title="Máquina" icon="cubes">
                                                 <Field name="Build_date"
                                                        component={renderInputField}
                                                        type="date"
                                                        label="Fecha de fabricación" />
                                                 <Field name="OldLocker"
                                                        component={renderSelectField}
                                                        label="Locker Antiguo">
                                                        <option value="">Tiene instalados lockers antiguos?</option>
                                                        <option value="true">Sí</option>
                                                        <option value="false">No</option>
                                                 </Field>
                                                 <Field name="GenderMode"
                                                        component={renderSelectField}
                                                        label="Segregación por sexo">
                                                        <option value="">Tienes taquillas en vestuarios no mixtos?</option>
                                                        <option value="true">Sí</option>
                                                        <option value="false">No</option>
                                                 </Field>
                                                 <Field name="LockersFirmwareVersion"
                                                        component={renderSelectField}
                                                        label="Firmware nuevo">
                                                        <option value="">Se ha hecho OTA con firmware nuevo?</option>
                                                        <option value="0">No</option>
                                                        <option value="1">Sí</option>
                                                 </Field>
                                                 <Field name="PrinterSensorActive"
                                                        component={renderSelectField}
                                                        label="Aviso de ticket">
                                                        <option value="">Habilitar aviso de ticket?</option>
                                                        <option value="true">Sí</option>
                                                        <option value="false">No</option>
                                                 </Field>
                                                 <Field name="StartPassRecovery"
                                                        component={renderSelectField}
                                                        label="Recuperación contraseña Start">
                                                        <option value="">Habilitar la recuperación de contraseña en Start?</option>
                                                        <option value="true">Sí</option>
                                                        <option value="false">No</option>
                                                 </Field>
                                                 <Field name="LedStatusIndicator"
                                                        component={renderSelectField}
                                                        label="Indicador de estado LED">
                                                        <option value="">Habilitar el indicador de estado LED?</option>
                                                        <option value="true">Sí</option>
                                                        <option value="false">No</option>
                                                 </Field>
                                                 <Field name="UndefinedLockerRental"
                                                        component={renderSelectField}
                                                        label="Bloqueo de alquiler (Port Aventura)">
                                                        <option value="">Habilitar el bloqueo del alquiler?</option>
                                                        <option value="true">Sí</option>
                                                        <option value="false">No</option>
                                                 </Field>
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-2">
                                          <Box title="Interficie" icon="desktop">
                                                 <Field name="GeneralSoundActive"
                                                        component={renderSelectField}
                                                        label="Pasos sonoros">
                                                        <option value="">Habilitar pasos sonoros?</option>
                                                        <option value="true">Sí</option>
                                                        <option value="false">No</option>
                                                 </Field>
                                                 <Field name="Sound_interval"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Intérvalo sonido burbujas" />
                                                 <Field name="LastSale"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Código de venta" />
                                                 <Field name="SleepStart"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Inicio del modo sleep (h)" />
                                                 <Field name="SleepEnd"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Fin del modo sleep (h)" />
                                                 <Field name="PaymentMethods"
                                                        component={renderSelectField}
                                                        label="Métodos de pago">
                                                        <option value="">Selecciona una combinación...</option>
                                                        <option value="0">CASH + TPV</option>
                                                        <option value="1">SOLO CASH</option>
                                                        <option value="2">SOLO TPV</option>
                                                        <option value="3">SOLO RFID</option>
                                                        <option value="4">GRATIS</option>
                                                 </Field>
                                                 <Field name="BlockU1000Cancelling"
                                                        component={renderSelectField}
                                                        label="Bloqueo Cancelar U1000">
                                                        <option value="">Bloquear cancelación U1000?</option>
                                                        <option value="true">Sí</option>
                                                        <option value="false">No</option>
                                                 </Field>
                                                 <Field name="AutoScreenExitTime"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Timeout pantallas" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-2">
                                          <Box title="Passwords" icon="unlock">
                                                 <Field name="PasswordMachine"
                                                        component={renderInputField}
                                                        type="text"
                                                        label="Pin Máquina" />
                                                 <Field name="PasswordFund"
                                                        component={renderInputField}
                                                        type="text"
                                                        label="Pin Cash" />
                                                 <Field name="PasswordCash"
                                                        component={renderInputField}
                                                        type="text"
                                                        label="Pin Universal" />
                                                 <Field name="PasswordMaster"
                                                        component={renderInputField}
                                                        type="text"
                                                        label="Pin Master" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-3">
                                          <Box title="Otros" icon="comments">
                                                 <Field name="standbyPhrase"
                                                        type="text"
                                                        component={renderTextAreaField}
                                                        label="Frase Standby" />
                                                 <Field name="Contacto"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Teléfono de incidencias" />
                                                 <Field name="Report_email"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="E-mail de reportes" />
                                                 <Field name="Comments"
                                                        type="text"
                                                        component={renderTextAreaField}
                                                        label="Comentarios" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-3">
                                          <Box title="Idiomas" icon="comments">
                                                 <Field name="Language"
                                                        component={renderSelectField}
                                                        label="Idioma principal">
                                                        <option value="">Selecciona un idioma...</option>
                                                        <option value="es_ES">ESPAÑOL</option>
                                                        <option value="en_EN">ENGLISH</option>
                                                        <option value="fr_FR">FRANÇAIS</option>
                                                        <option value="it_IT">ITALIANO</option>
                                                        <option value="gr_GR">DEUTSCH</option>
                                                        <option value="cz_CZ">ČEŠTINA</option>
                                                        <option value="cat_CAT">CATALÀ</option>
                                                        <option value="pt_PT">PORTUGUESE</option>
                                                        <option value="nw_NW">NORSK</option>
                                                        <option value="nl_NL">NEDERLAND</option>
                                                 </Field>
                                                 <FieldArray name="SupportedLanguages" component={this.renderLanguages} />
                                          </Box>
                                   </div>
                            </div>

                            <h3>
                                   <span className="text-vw-dark">OPERATIVA</span>
                            </h3>

                            <div className="row mb-5">
                                   <div className="col-xs-12 col-md-2">
                                          <Box title="Day Scan" icon="comments">
                                                 <Field name="ScanDayAllowed"
                                                        component={renderSelectField}
                                                        label="Day Scan activo?">
                                                        <option value='false'>NO</option>
                                                        <option value='true'>SI</option>
                                                 </Field>
                                                 <Field name="ScanDayInterval"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Day Scan Interval"/>
                                                 <Field name="PassOpens"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Número de aperturas"/>
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-2">
                                          <Box title="QR" icon="comments">
                                                 <Field name="QRActive"
                                                        component={renderSelectField}
                                                        label="Venta QR Habilitada">
                                                        <option value='false'>NO</option>
                                                        <option value='true'>SI</option>
                                                 </Field>
                                                 <Field name="QRPercentage"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Porcentaje de reserva" />
                                                 <Field name="QRMode"
                                                        component={renderSelectField}
                                                        label="Modo de funcionamiento">
                                                        <option value="">Selecciona un modo...</option>
                                                        <option value="0">MTO DIARIO</option>
                                                        <option value="1">OTO DIARIO SIN PIN</option>
                                                        <option value="2">OTO DIARIO CON PIN</option>
                                                 </Field>
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-2">
                                          <Box title="Reserva" icon="comments">
                                                 <Field name="BookActive"
                                                        component={renderSelectField}
                                                        label="Reserva habilitada">
                                                        <option value='false'>NO</option>
                                                        <option value='true'>SI</option>
                                                 </Field>
                                                 <Field name="BookPercentage"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Porcentaje de reserva" />
                                                 <Field name="BookFreeTime"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Hora de liberación" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-2">
                                          <Box title="Limpieza" icon="comments">
                                                 <Field name="CleanActive"
                                                        component={renderSelectField}
                                                        label="Limpieza Habilitada">
                                                        <option value='false'>NO</option>
                                                        <option value='true'>SI</option>
                                                 </Field>
                                                 <Field name="CleanAll"
                                                        component={renderSelectField}
                                                        label="Limpiar Siempre">
                                                        <option value='false'>NO</option>
                                                        <option value='true'>SI</option>
                                                 </Field>
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-2">
                                          <Box title="Configuración" icon="lock">
                                                 <Field name="ScanNightAllowed"
                                                        component={renderSelectField}
                                                        label="Night Scan activo">
                                                        <option value='true'>SI</option>
                                                        <option value='false'>NO</option>
                                                 </Field>
                                                 <Field name="AlarmDefault"
                                                        component={renderSelectField}
                                                        label="Alarma de locker abierto">
                                                        <option value='false'>NO</option>
                                                        <option value='true'>SI</option>
                                                 </Field>
                                                 <Field name="BuzzerDefault"
                                                        component={renderSelectField}
                                                        label="Sonido de teclado activo">
                                                        <option value='1'>SI</option>
                                                        <option value='0'>NO</option>
                                                 </Field>
                                                 <Field name="OpenModeDefault"
                                                        component={renderSelectField}
                                                        label="Cierre de locker manual">
                                                        <option value='false'>NO</option>
                                                        <option value='true'>SI</option>
                                                 </Field>
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-2">
                                          <Box title="Firmware Nuevo" icon="lock">
                                                 {/* <Field name="PasswordUniversal"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Password Universal" /> */}
                                                 <Field name="PasswordLength"
                                                        component={renderSelectField}
                                                        label="Carácteres Contraseña">
                                                        <option value='4'>4</option>
                                                        <option value='6'>6</option>
                                                 </Field>
                                                 <Field name="BLEPower"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Potencia BLE (dB)" />
                                                 <Field name="BLEAdvTime"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Cadéncia BLE (ms)" />
                                                 <Field name="Keypad"
                                                        component={renderSelectField}
                                                        label="Encender Teclado">
                                                        <option value='0'>NO</option>
                                                        <option value='1'>SI</option>
                                                 </Field>
                                          </Box>
                                   </div>
                            </div>

                            <h3>
                                   <span className="text-vw-dark">PAYMENTS</span>
                            </h3>

                            <div className="row mb-5">
                                   <div className="col-xs-12 col-md-3">
                                          <Box title="Dispositivos">
                                                 <FieldArray name="PaymentDevices" component={this.renderPaymentDevices} />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-2">
                                          <Box title="TPV" icon="credit-card">
                                                 <Field name="Tpv_serial"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Número de serie" />
                                                 <Field name="Tpv_max"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Cargo máximo (EUR)" />
                                                 <Field name="TpvCancelTime"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Timeout de cancelación" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-2">
                                          <Box title="PAYTEF" icon="credit-card">
                                                 <Field name="Tpv_tcod"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="TCOD" />
                                          </Box>
                                          <Box title="REDSYS" icon="credit-card">
                                                 <Field name="TpvBusinessCode"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Código de comercio" />
                                                 <Field name="TpvTerminalCode"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Número de Terminal" />
                                                 <Field name="TpvFirmKeyCode"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Clave de Firma" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-2">
                                          <Box title="CASH" icon="coins">
                                                 <Field name="CashMax"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Cobro máximo en Cash (EUR)" />
                                                 <Field name="SecurityBox"
                                                        component={renderSelectField}
                                                        label="Caja de seguridad">
                                                        <option value='true'>SI</option>
                                                        <option value='false'>NO</option>
                                                 </Field>
                                                 <Field name="BillerAcceptance"
                                                        component={renderSelectField}
                                                        label="Billetes aceptados">
                                                        <option value='1111'>TODOS 5€ / 10€ / 20€ / 50€</option>
                                                        <option value='0111'>5€ / 10€ / 20€</option>
                                                        <option value='0011'>5€ / 10€</option>
                                                        <option value='0001'>5€</option>
                                                        <option value='0000'>NINGÚNO</option>
                                                 </Field>
                                                 <Field name="CoinAcceptance"
                                                        component={renderSelectField}
                                                        label="Monedas aceptadas">
                                                        <option value='0'>0,50€</option>
                                                        <option value='1'>1€</option>
                                                        <option value='2'>2€</option>
                                                        <option value='3'>1€ / 2€</option>
                                                 </Field>
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-2">
                                          <Box title="Devoluciones" icon="coins">
                                                 <Field name="LimiteDevolucionDiario"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Máximo de devolución diario (EUR)" />
                                                 <Field name="LimiteDevolucion"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Máximo de devolución de una sola vez (EUR)" />
                                          </Box>
                                   </div>
                            </div>

                            <h3>
                                   <span className="text-vw-dark">MÓDULOS Y SEGURIDAD</span>
                            </h3>

                            <div className="row mb-5">
                                   <div className="col-xs-12 col-md-6">
                                          <Box title="Módulos">
                                                 <FieldArray name="Modules" component={this.renderModules} />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-6">
                                          <Box title="Seguridad">
                                                 <FieldArray name="SecurityLockers" component={this.renderPairingSecurity} />
                                          </Box>
                                   </div>
                            </div>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <button className="btn bg-vw-light text-white mr-1 save" type="submit"
                                   disabled={pristine || submitting}>
                                   Guardar
                            </button>
                     </form>
              )
       }
}

MachineAddForm = reduxForm({
       form: 'MachineAddForm',
       keepDirtyOnReinitialize: true,
       enableReinitialize: true,
       updateUnregisteredFields: true,
       onSubmitSuccess: (result, dispatch, props) => {
              props.push(`/machines`)
       }
})(MachineAddForm)

const mapStateToProps = state => ({
       auth: state.auth,
       role: state.auth.role,
       type: state.auth.type,
       fullname: state.auth.fullname,
})

const mapDispatchToProps = dispatch => ({
       addMachine: bindActionCreators(addMachine, dispatch),
       push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MachineAddForm)
